import lottie from 'lottie-web';
import * as animationData from './hofreiter-404-lottie-animation.json';

var lottieContainer = document.getElementById('lottie');
var lottieAnimation;

export function runLottieAnimation() {
    if (lottieContainer) {
        lottieAnimation = lottie.loadAnimation({
            container: lottieContainer,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: animationData.default,
        });
    }
};
