export function customSelect() {

    if (document.getElementsByClassName('custom-select-wrapper').length) {
        document.querySelector('.custom-select-wrapper').addEventListener('click', function () {
            this.querySelector('.custom-select').classList.toggle('open');
        })
        document.querySelector('.custom-select-wrapper').addEventListener('mouseenter', function () {
            this.querySelector('.custom-select').classList.add('open');
        })

        document.querySelector('.custom-select-wrapper').addEventListener('mouseleave', function () {
            this.querySelector('.custom-select').classList.remove('open');
        })

        for (const option of document.querySelectorAll(".custom-option")) {
            option.addEventListener('click', function () {
                if (!this.classList.contains('selected')) {
                    this.parentNode.querySelector('.custom-option.selected').classList.remove('selected');
                    this.classList.add('selected');
                    ajaxCall(this.dataset.id);
                }
            })

        }

        window.addEventListener('click', function (e) {
            const select = document.querySelector('.custom-select');
            if (!select.contains(e.target)) {
                select.classList.remove('open');
            }
        });
    }


    function ajaxCall(searchedText) {
        var loading = document.getElementById('loading_animation');
        var container = document.getElementById('container-ajax')
        var button = document.getElementById('loadmore');
        steven_loadmore_params.current_page = 0;
        var beforeSend = function () {
            container.innerHTML = ''
            loading.classList.toggle('is-hidden')
            button.style.display = 'none';
        }
        beforeSend()
        var xhr = new XMLHttpRequest();
        xhr.open('POST', steven_loadmore_params.ajaxurl);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {

            if (xhr.status === 200) {
                if (xhr.responseText) {
                    document.querySelector('.custom-select').classList.remove('open')
                    loading.classList.toggle('is-hidden')
                    button.style.display = 'inline-flex'
                    container.innerHTML = xhr.response;
                    steven_loadmore_params.current_page++;
                    var max = 0
                    max = document.getElementById('max-page');
                    steven_loadmore_params.max_page = max.dataset.maxPage;
                    steven_loadmore_params.cat = searchedText;
                    if (steven_loadmore_params.current_page >= steven_loadmore_params.max_page) {
                        button.style.display = 'none';
                    }
                } else {
                    // console.log('no post')
                    button.style.display = 'none';
                }
            }
        }
        xhr.send("action=filter_pressetext&query=" + searchedText + "&page=" + steven_loadmore_params.current_page)
        return false;

    }
}