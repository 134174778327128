export function accordion() {
    const accordions = [...document.getElementsByClassName('accordion')];
    accordions.map((accordion, i) => {
        const collapsibleArea = accordion.getElementsByClassName('is-collapse')[0];
        const actionButton = accordion.getElementsByClassName('opening-icon')[0];
        actionButton.addEventListener('click', () => {
            if (collapsibleArea.style.maxHeight) {
                collapsibleArea.style.maxHeight = null;
                actionButton.classList.remove('open');
            } else {
                collapsibleArea.style.maxHeight = collapsibleArea.scrollHeight + "px";
                actionButton.classList.add('open');
            }
            let restAccordions = accordions.slice();
            restAccordions.splice(i, 1);
            restAccordions.map(accordion => {
                const innerCollapsible = accordion.getElementsByClassName('is-collapse')[0];
                const innerButton = accordion.getElementsByClassName('opening-icon')[0];
                innerCollapsible.style.maxHeight = null;
                innerButton.classList.remove('open');
            })
        });
    })
};