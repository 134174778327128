import Swiper from 'swiper/bundle';

import 'swiper/swiper-bundle.css';

export function initializeSlider() {
    // header slider
    if (document.getElementsByClassName('hero').length) {
        [...document.getElementsByClassName('hero')].map(slider => {

            let wrapper = document.querySelector('.hero');
            let prevBtn = wrapper.querySelector('.swiper-button-prev');
            let nextBtn = wrapper.querySelector('.swiper-button-next');

            if (prevBtn) {
                prevBtn.classList.add('header-button-prev');
            } if (nextBtn) {
                nextBtn.classList.add('header-button-next');
            }
            const headerSlider = new Swiper('.header-slider', {
                // Default parameters
                slidesPerView: 'auto',
                spaceBetween: 10,
                centeredSlides: true,
                loop: true,
                lazy: true,
                navigation: {
                    nextEl: '.header-button-next',
                    prevEl: '.header-button-prev',
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        centeredSlides: false,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                        centeredSlides: false,
                    },
                    1408: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                        centeredSlides: false,
                    }
                }
            });

        });
    }
    // video slider
    if (document.getElementsByClassName('video').length) {
        [...document.getElementsByClassName('video')].map(slider => {

            let wrapper = document.querySelector('.video');
            let prevBtn = wrapper.querySelector('.swiper-button-prev');
            let nextBtn = wrapper.querySelector('.swiper-button-next');

            if (prevBtn) {
                prevBtn.classList.add('video-button-prev');
            } if (nextBtn) {
                nextBtn.classList.add('video-button-next');
            }

            const videoSlider = new Swiper('.video-slider', {
                // Default parameters
                slidesPerView: 'auto',
                centeredSlides: true,
                loop: true,
                navigation: {
                    nextEl: '.video-button-next',
                    prevEl: '.video-button-prev',
                }
            });
        });
    }
    // presse slider
    if (document.getElementsByClassName('presse').length) {
        [...document.getElementsByClassName('presse')].map(slider => {
            let wrapper = document.querySelector('.presse');
            let prevBtn = wrapper.querySelector('.swiper-button-prev');
            let nextBtn = wrapper.querySelector('.swiper-button-next');
            if (prevBtn) {
                prevBtn.classList.add('presse-button-prev');
            } if (nextBtn) {
                nextBtn.classList.add('presse-button-next');
            }
            const presseSlider = new Swiper('.presse-slider', {
                // Default parameters
                slidesPerView: 2,
                spaceBetween: 10,
                centeredSlides: false,
                loop: false,
                navigation: {
                    nextEl: '.presse-button-next',
                    prevEl: '.presse-button-prev',
                },
                breakpoints: {
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                        
                    },
                    1024: {
                        slidesPerView: 'auto',
                        spaceBetween: 30,
                    }
                }
                
            });
        });
    }
    // news slider
    if (document.getElementsByClassName('news').length) {
        [...document.getElementsByClassName('news')].map(slider => {
            let wrapper = document.querySelector('.news');
            let prevBtn = wrapper.querySelector('.swiper-button-prev');
            let nextBtn = wrapper.querySelector('.swiper-button-next');
            if (prevBtn) {
                prevBtn.classList.add('news-button-prev');
            } if (nextBtn) {
                nextBtn.classList.add('news-button-next');
            }
            const newsSlider = new Swiper('.news-slider', {
                // Default parameters
                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,
                lazy: true,
                navigation: {
                    nextEl: '.news-button-next',
                    prevEl: '.news-button-prev',
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1444: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    }
                }
            });
        });
    }

}

