const navBar = document.querySelector(".navbar");
const navMenu = document.querySelector(".navbar-menu");
const burger = document.querySelector(".navbar-burger");
const overlay = document.querySelector('.overlay');
const html = document.querySelector('html');

export function Navigation() {

    burger.addEventListener("click", function () {
        navBar.classList.toggle("is-active");
        navMenu.classList.toggle("is-active");
        burger.classList.toggle("is-active");
        overlay.classList.toggle("is-active");
        html.classList.toggle("is-clipped");
    });

    // Add extra class to responsive menu 
    if (window.innerWidth < 1024 || document.documentElement.clientWidth < 1024 || document.body.clientWidth < 1024) {
        navBar.classList.add('is-responsive-menu');
    }

    // Transparent header for homepage
    if (window.location.href == backendParams.homeUrl) {
        if (!navBar.classList.contains('is-responsive-menu')) {
            navBar.classList.add('is-transparent');
        }
    }

    window.addEventListener('scroll', function () {
        if (document.body.scrollTop > 70 || document.documentElement.scrollTop > 70) {
            // make menu sticky 
            navBar.classList.add('is-fixed-top');
            html.classList.add('has-navbar-fixed-top');
            // Add/remove menu transparency on homepage
            navBar.classList.remove('is-transparent');
        } else {
            // disable sticky
            navBar.classList.remove('is-fixed-top');
            html.classList.remove('has-navbar-fixed-top');
            // Add/remove menu transparency on homepage
            if (window.location.href == backendParams.homeUrl) {
                if (!navBar.classList.contains('is-responsive-menu')) {
                    navBar.classList.add('is-transparent');
                }
            }
        }
    });

}
