import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export function slidersGridAnimation() {
    const gridSections = [...document.getElementsByClassName('news-grid')];
    const sliderSections = [...document.getElementsByClassName('swiper-container')];

    function runAnimation(sections) {
        sections.map(section => {
            const boxes = [...section.getElementsByClassName('post-content')];
            const slides = [...section.getElementsByClassName('swiper-slide')];
            let slideArrowLeft;
            let slideArrowRight;
            let delay;

            if (boxes) {
                boxes.map(box => box.classList.add('slidersGridAnimation'));
            } if (slides) {
                const sliderContainer = section.parentElement;
                const mainSection = sliderContainer.parentElement;
                if (mainSection) {
                    if (mainSection.classList.contains('hero')) {
                        delay = 3;
                    } else {
                        delay = 2;
                    }
                }
                slides.map(slide => slide.classList.add('slidersGridAnimation'));
                slideArrowLeft = [...sliderContainer.getElementsByClassName('swiper-button-prev')][0];
                slideArrowRight = [...sliderContainer.getElementsByClassName('swiper-button-next')][0];
            }

            gsap.defaults({ ease: "Power4.easeOut", duration: 1 });
            gsap.set('.slidersGridAnimation', { y: '15%', opacity: 0 });

            ScrollTrigger.batch('.slidersGridAnimation', {
                onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.2, overwrite: true }),
            });

            if (slideArrowLeft) {

                gsap.fromTo(slideArrowLeft, {
                    x: '-25%',
                    opacity: 0,
                }, {
                    x: '0%',
                    opacity: 1,
                    delay: delay,
                    ease: "Power4.easeOut",
                    scrollTrigger: {
                        trigger: section,
                        start: 'top bottom-=150',
                    },
                });
            } if (slideArrowRight) {

                gsap.fromTo(slideArrowRight, {
                    x: '25%',
                    opacity: 0,
                }, {
                    x: '0%',
                    opacity: 1,
                    delay: delay,
                    ease: "Power4.easeOut",
                    scrollTrigger: {
                        trigger: section,
                        start: 'top bottom-=150',
                    },
                });
            }

        });
    }

    if (gridSections.length) {
        runAnimation(gridSections);
    } if (sliderSections.length) {
        runAnimation(sliderSections);
    }
};

export function showNavigation() {
    const header = [...document.getElementsByClassName('navbar')][0];

    if (header) {

        gsap.from(header, {
            opacity: 0,
            duration: 1.5,
        });
    }
};

export function showBannerText() {
    const banners = [...document.getElementsByClassName('banner')];

    if (banners) {
        banners.map(banner => {
            const box = [...banner.getElementsByClassName('img-box')][0];
            const content = [...banner.getElementsByClassName('text-content')][0];

            gsap.fromTo(box, {
                y: '10%',
                opacity: 0
            }, {
                y: '0%',
                opacity: 1,
                duration: 1.5,
                delay: 0.5,
                ease: "Power4.easeOut",
                scrollTrigger: {
                    trigger: banner,
                    start: 'top bottom-=150',
                },
            });

            gsap.fromTo(content, {
                x: '-20%',
                opacity: 0
            }, {
                x: '0%',
                opacity: 1,
                duration: 1.5,
                delay: 0.5,
                ease: "Power4.easeOut",
                scrollTrigger: {
                    trigger: banner,
                    start: 'top bottom-=150',
                },
            });
        })
    }
};

export function textMediaAnimation() {
    const textMediaSections = [...document.getElementsByClassName('text-with-media')];

    if (textMediaSections.length) {

        textMediaSections.map(section => {
            const columns = section.getElementsByClassName('columns')[0];
            if (columns) {
                const media = section.getElementsByClassName('image')[0];
                const column = [...columns.getElementsByClassName('column')];

                gsap.from(column[1], {
                    scrollTrigger: {
                        trigger: column[1],
                        start: 'top bottom-=150',
                    },
                    opacity: 0,
                    duration: 2.5,
                    y: '5%',
                });

                if (columns.classList.contains('is-desktop-reverse')) {

                    if (media) {
                        gsap.from(media, {
                            scrollTrigger: {
                                trigger: media,
                                start: 'top bottom-=150',
                            },
                            x: '25%',
                            opacity: 0,
                            duration: 1
                        });
                    }

                } else {

                    if (media) {
                        gsap.from(media, {
                            scrollTrigger: {
                                trigger: media,
                                start: 'top bottom-=150',
                            },
                            x: '-25%',
                            opacity: 0,
                            duration: 1
                        });
                    }
                }
            }
        });
    }
};

export function textAnimation() {
    const textSections = [...document.getElementsByClassName('text')];

    if (textSections.length) {

        textSections.map(section => {
            const columns = [...section.getElementsByClassName('column')];
            if (columns) {
                if (columns.length === 1) {

                    gsap.from(columns[0], {
                        scrollTrigger: {
                            trigger: columns[0],
                            start: 'top bottom-=150',
                        },
                        opacity: 0,
                        duration: 2.5,
                        y: '5%',
                    });

                } else {
                    gsap.from(columns[0], {
                        scrollTrigger: {
                            trigger: columns[0],
                            start: 'top bottom-=150',
                        },
                        opacity: 0,
                        duration: 2.5,
                        x: '-15%',
                    });

                    gsap.from(columns[1], {
                        scrollTrigger: {
                            trigger: columns[1],
                            start: 'top bottom-=150',
                        },
                        opacity: 0,
                        duration: 2.5,
                        x: '15%',
                    });
                }
            }
        });
    }
};

export function titleAnimation() {
    const imageAreaSections = [...document.getElementsByClassName('image-area')];

    if (imageAreaSections.length) {

        imageAreaSections.map(section => {
            const content = section.getElementsByClassName('img-content')[0];

            gsap.from(content, {
                scrollTrigger: {
                    trigger: section,
                    start: 'top bottom-=100',
                },
                x: '5%',
                duration: 2.5,
            });
        });
    }
};

export function contactWithImages() {
    const sections = [...document.getElementsByClassName('image-contacts')];

    if (sections.length) {

        sections.map(section => {
            const columns = [...section.getElementsByClassName('column')];

            if (columns) {
                columns.map((column, i) => {
                    const col = column.getElementsByClassName('columns')[0];

                    if (i === 0) {
                        gsap.from(col, {
                            scrollTrigger: {
                                trigger: section,
                                start: 'top bottom-=150',
                            },
                            x: '-25%',
                            opacity: 0,
                            duration: 1
                        });
                    } else {
                        gsap.from(col, {
                            scrollTrigger: {
                                trigger: section,
                                start: 'top bottom-=150',
                            },
                            x: '25%',
                            opacity: 0,
                            duration: 1
                        });
                    }
                })
            }
        });
    }
};

export function showLogos() {
    const gridSections = [...document.getElementsByClassName('grid')];

    gridSections.map(section => {
        const logos = [...section.getElementsByClassName('column')];

        if (logos) {
            logos.map(logo => logo.classList.add('logoAnimation'));
        }

        gsap.defaults({ ease: "Power4.easeOut", duration: 1 });
        gsap.set('.logoAnimation', { x: '15%', opacity: 0 });

        ScrollTrigger.batch('.logoAnimation', {
            onEnter: batch => gsap.to(batch, { opacity: 1, x: 0, stagger: 0.2, overwrite: true }),
        });

    });
};

export function parallaxPicture() {

    if (!document.body.classList.contains('home')) {
        const heroArea = document.getElementsByClassName('hero-area-imageBg')[0];

        if (heroArea) {
            const img = heroArea.getElementsByTagName('img')[0];

            if (img) {

                gsap.fromTo(img, {
                    y: '-15%'
                }, {
                    y: '8%',
                    ease: "none",
                    scrollTrigger: {
                        trigger: img,
                        scrub: 1
                    },
                });
            }
        }
    }
}


// Single Post

export function weiterePosts() {
    const sections = [...document.getElementsByClassName('single-themen')];

    if (sections) {
        sections.map(section => {
            const weiterePosts = [...section.getElementsByClassName('weitere-posts')][0];
            const picture = section.getElementsByClassName('featured-image')[0];

            if (weiterePosts) {
                const singlePosts = [...weiterePosts.getElementsByClassName('post-content')];

                if (singlePosts) {
                    singlePosts.map(singlePost => singlePost.classList.add('singlePostAnimation'));

                    gsap.defaults({ ease: "Power4.easeOut", duration: 1.5 });
                    gsap.set('.singlePostAnimation', { x: '25%', opacity: 0 });

                    ScrollTrigger.batch('.singlePostAnimation', {
                        onEnter: batch => gsap.to(batch, { opacity: 1, x: 0, stagger: 0.35, overwrite: true }),
                    });
                }
            } if (picture) {
                gsap.from(picture, {
                    scrollTrigger: {
                        trigger: section,
                        start: 'top bottom-=100',
                    },
                    opacity: 0,
                    duration: 2,
                });
            }
        })
    }
};

export function quoteAnimation() {
    const quotes = [...document.getElementsByClassName('zitat')];

    if (quotes.length) {

        quotes.map(quote => {
            quote.classList.add('quoteAnimation');

            gsap.defaults({ ease: "power4", duration: 1 });
            gsap.set('.quoteAnimation', { y: '30%', opacity: 0 });

            ScrollTrigger.batch('.quoteAnimation', {
                onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, duration: 2, stagger: 0.2, overwrite: true }),
            });
        });
    }
};