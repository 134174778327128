export function scrollDown() {
    const arrow = document.querySelector('.header-arrow');

    if (arrow) {
        const currentSection = arrow.closest('.section');

        arrow.addEventListener('click', function () {
            let yOffset;

            if (window.innerWidth < 1024 || document.documentElement.clientWidth < 1024 || document.body.clientWidth < 1024) {
                yOffset = -100;
            } else {
                yOffset = -150;
            }

            const nextSection = currentSection.nextElementSibling;
            const y = nextSection.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        });
    }
}