// import Velocity from "velocity-animate"; use this for complex animation
import { Navigation } from '../js-components/navigation';
import { changeSizeLogo } from '../js-components/changeSizeLogo';
import { accordion } from '../js-components/accordion';
import { initializeSlider } from '../js-components/slider';
import { customSelect } from '../js-components/customSelect';
import { loadmore } from "../js-components/loadmore";
import { scrollDown } from '../js-components/scrollDown';
import { initializeAnimations } from '../js-components/initializeAnimations';
import { runLottieAnimation } from '../js-components/runLottieAnimation';

export default {

  init() {
    Navigation();
    //changeSizeLogo();
    initializeSlider();
    accordion();
    customSelect();
    loadmore();
    initializeAnimations();
    scrollDown();
    runLottieAnimation();
  }
}
