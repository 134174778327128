import { slidersGridAnimation, showNavigation, showBannerText, textMediaAnimation, contactWithImages, titleAnimation, showLogos, weiterePosts, quoteAnimation, textAnimation, parallaxPicture } from './singleAnimations';

export function initializeAnimations() {
    showNavigation();
    slidersGridAnimation();
    showBannerText();
    textMediaAnimation();
    contactWithImages();
    titleAnimation();
    showLogos();
    weiterePosts();
    quoteAnimation();
    textAnimation();
    parallaxPicture();
};
